:export {
  colorBackgroundMain: var(--color-background-main);
  colorBackground: var(--color-background);
  /* other variables */
}

:root {
  --color-primary: #2563eb;
  --color-primary-dark: #1d4ed8;
  --color-secondary: #f3f4f6;
  --color-accent: #f97316;
  --color-text-primary: #111827;
  --color-text-secondary: #6b7280;
  --color-background-main: #f0f2f5;
  --color-background: #ffffff;
  /* Keep white for cards and content areas */
  --color-border: #d1d5db;
  --color-error: #dc2626;
  --color-error-light: #fef2f2;

  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* Typography */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;

  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 6px;
  --radius-lg: 8px;

  /* Shadows */
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* src/styles/global.css */
html,
body,
#root {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: var(--color-background-main);
}

/* Reset all default backgrounds to transparent */
* {
  background-color: transparent;
}

/* Components that should have the white background */
.card,
.modal,
.dialog,
.content-section {
  background-color: var(--color-background);
}