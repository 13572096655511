.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
}

.presetAmounts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    margin: 20px 0;
}

.presetButton {
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.2s;
}

.presetButton.selected {
    background: #ef4444;
    color: white;
    border-color: #ef4444;
}

.customAmount {
    margin: 20px 0;
    text-align: left;
}

.inputWrapper {
    display: flex;
    align-items: center;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 8px;
    margin-top: 4px;
}

.inputWrapper span {
    color: #6b7280;
    margin-right: 4px;
}

.inputWrapper input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
}

.donateButton {
    background: #ef4444;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}

.donateButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.error {
    color: #ef4444;
    margin-top: 8px;
    font-size: 14px;
}

.anonymousOption {
    margin: 1rem 0;
    text-align: left;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.checkboxLabel input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}