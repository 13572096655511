/* SharedRequestNotification.module.css */
.container {
  padding: 16px;
  background-color: #f3f4f6;
}

.requestsList {
  margin-top: 16px;
}

/* Keep your existing notification badge styles */
.notificationBadge {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.bellIcon {
  color: #4f46e5;
  width: 24px;
  height: 24px;
}

.count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ef4444;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  min-width: 18px;
  text-align: center;
}

.tooltip {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #1f2937;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  z-index: 50;
}

.notificationBadge:hover .tooltip {
  opacity: 1;
  visibility: visible;
}