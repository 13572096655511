/* src/components/auth/OAuthError.module.css */
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    padding: 2rem;
}

.errorCard {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.title {
    font-size: 1.8rem;
    color: #e53935;
    margin: 1rem 0;
}

.message {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.errorDetails {
    background-color: #ffebee;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    color: #b71c1c;
    font-size: 0.9rem;
    text-align: left;
    word-break: break-word;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.primaryButton {
    padding: 0.75rem 1rem;
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.primaryButton:hover {
    background-color: #303f9f;
}

.secondaryButton {
    padding: 0.75rem 1rem;
    background-color: transparent;
    color: #3f51b5;
    border: 1px solid #3f51b5;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.secondaryButton:hover {
    background-color: rgba(63, 81, 181, 0.05);
}

.helpText {
    font-size: 0.85rem;
    color: #666;
}

.errorIcon {
    margin: 0 auto;
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ff5252;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorIcon:before,
.errorIcon:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 4px;
    background-color: white;
    border-radius: 2px;
}

.errorIcon:before {
    transform: rotate(45deg);
}

.errorIcon:after {
    transform: rotate(-45deg);
}

@media (min-width: 768px) {
    .actions {
        flex-direction: row;
        justify-content: center;
    }

    .primaryButton,
    .secondaryButton {
        min-width: 150px;
    }
}