.container {
  background-color: var(--color-background-main);
}

.card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.description {
  color: #4a5568;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
}

.metadata {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 0.875rem;
  color: #718096;
}

.budget {
  font-weight: 600;
  color: #48bb78;
}

.date {
  color: #718096;
}

.newBadge {
  background: #4299e1;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 600;
}

.actions {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}

.viewButton,
.conversationButton {
  width: 180px;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.viewButton {
  background: #edf2f7;
  color: #2d3748;
  border: 1px solid #e2e8f0;
}

.viewButton:hover {
  background: #e2e8f0;
}

.conversationButton {
  background: #4299e1;
  color: white;
  border: none;
}

.conversationButton:hover {
  background: #3182ce;
}

.viewButton:disabled,
.conversationButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.error {
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 8px;
}