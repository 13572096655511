.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
}

.dialog {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 420px;
  overflow: hidden;
  position: relative;
  animation: slideUp 0.3s ease-out;
}

.header {
  padding: 24px 24px 0 24px;
  text-align: center;
  position: relative;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 8px 0;
}

.description {
  font-size: 14px;
  color: #6b7280;
  margin: 0 0 16px 0;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton:hover {
  background-color: #f3f4f6;
  color: #4b5563;
}

.content {
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.error {
  background-color: #fee2e2;
  color: #b91c1c;
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.primaryButton {
  background-color: #3b82f6;
  color: white;
  font-weight: 500;
  padding: 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
}

.primaryButton:hover {
  background-color: #2563eb;
}

.divider {
  display: flex;
  align-items: center;
  margin: 8px 0;
  color: #9ca3af;
  font-size: 14px;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: #e5e7eb;
}

.dividerText {
  padding: 0 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

.oauthButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background-color: white;
  color: #374151;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
  text-decoration: none;
  font-size: 14px;
}

.oauthButton:hover {
  background-color: #f9fafb;
  border-color: #d1d5db;
}

.oauthIcon {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.footer {
  margin-top: 8px;
  text-align: center;
}

.registerText {
  font-size: 14px;
  color: #6b7280;
  margin: 0;
}

.textButton {
  background: none;
  border: none;
  color: #3b82f6;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.textButton:hover {
  text-decoration: underline;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}