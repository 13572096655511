.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 102, 255, 0.1);
  padding: 0;
  border-bottom: 1px solid var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  z-index: 1000;
  box-sizing: border-box;
}

.iconBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 var(--spacing-md);
  box-sizing: border-box;
}

.icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-md);
  background-color: transparent;
  color: var(--color-text-secondary);
}

.icon:hover {
  transform: translateY(-2px);
  background-color: var(--color-background-hover);
  color: var(--color-primary);
}

/* Add to your Header.module.css */
.iconImage {
  transition: all 0.2s ease;
  width: 24px;
  height: 24px;
}

.icon:hover .iconImage {
  transform: scale(1.1);
}

.icon.active {
  background-color: var(--color-background-hover);
  color: var(--color-primary);
}

.icon.active .iconImage {
  color: var(--color-primary);
}

.leftSection {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  gap: 0;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.settingsButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.settingsButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.notificationContainer {
  margin: 0 var(--spacing-sm);
}

.feedbackIconWrapper {
  display: none;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  border: none;
  background: none;
  cursor: pointer;
  color: var(--color-text-secondary);
  font-size: var(--font-size-base);
  transition: all 0.2s ease;
  border-radius: var(--radius-md);
}

.menuItem:hover {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.menuContainer {
  margin-left: 0;
}

.signInButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: 6px 12px;
  border: none;
  border-radius: var(--radius-md);
  background-color: var(--color-secondary);
  color: var(--color-text-primary);
  cursor: pointer;
  font-size: var(--font-size-base);
  font-weight: 500;
  transition: all 0.2s ease;
  min-height: 32px;
  margin-left: var(--spacing-sm);
}

.signInButton:hover {
  transform: translateY(-2px);
  background-color: var(--color-border);
}

.signInButton svg {
  stroke-width: 1.5;
  color: var(--color-text-primary);
}

.specialIcon {
  color: #ef4444 !important;
}

.specialIcon:hover {
  background-color: rgba(239, 68, 68, 0.1) !important;
  transform: translateY(-2px);
}

.specialIcon .iconImage {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.icon.specialIcon:hover .iconImage {
  transform: scale(1.2);
  color: #ef4444;
}

/* Tablet and below */
@media (max-width: 768px) {
  .header {
    height: 60px;
  }

  .iconBar {
    padding: 0 var(--spacing-sm);
    gap: var(--spacing-sm);
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .iconImage {
    width: 20px;
    height: 20px;
  }

  .leftSection {
    gap: var(--spacing-md);
  }
}

/* Mobile */
@media (max-width: 640px) {
  .signInButton {
    padding: 6px;
  }

  .signInButton span {
    display: none;
  }

  .leftSection {
    gap: var(--spacing-sm);
  }

  .icon {
    padding: 6px;
  }

  .iconBar {
    gap: 8px;
    padding: 0 8px;
  }

  .notificationContainer {
    margin: 0 4px;
  }

  .iconImage {
    width: 18px;
    height: 18px;
  }
}

/* Tablet and above */
@media screen and (min-width: 600px) {
  .feedbackIconWrapper {
    display: block;
  }
}