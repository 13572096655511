

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .dialog {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .description {
    margin-bottom: 1.5rem;
    line-height: 1.5;
    color: #666;
  }
  
  .error {
    background-color: #fee2e2;
    color: #dc2626;
    padding: 0.75rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  
  .cancelButton {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    background-color: white;
    color: #374151;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .cancelButton:hover {
    background-color: #f3f4f6;
  }
  
  .cancelButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .subscribeButton {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: #2563eb;
    color: white;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .subscribeButton:hover {
    background-color: #1d4ed8;
  }
  
  .subscribeButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }