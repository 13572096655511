

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem;
  }
  
  .modal {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    max-width: 28rem;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  
  .closeButton {
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
  }
  
  .closeButton:hover {
    background-color: #f3f4f6;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }
  
  .starContainer {
    display: flex;
    gap: 0.5rem;
  }
  
  .starButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #d1d5db;
  }
  
  .star {
    width: 2rem;
    height: 2rem;
    transition: all 0.2s;
  }
  
  .starActive {
    color: #fbbf24;
    fill: #fbbf24;
  }
  
  .input {
    padding: 0.625rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  .input:focus {
    outline: none;
    border-color: #2563eb;
    ring: 2px solid #2563eb;
  }
  
  .textarea {
    padding: 0.625rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    resize: vertical;
    min-height: 6rem;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #2563eb;
    ring: 2px solid #2563eb;
  }
  
  .error {
    padding: 0.75rem;
    background-color: #fee2e2;
    border-radius: 0.375rem;
    color: #991b1b;
    font-size: 0.875rem;
  }
  
  .successMessage {
    padding: 1rem;
    background-color: #ecfdf5;
    border-radius: 0.375rem;
    color: #065f46;
    text-align: center;
    font-size: 0.875rem;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
  }
  
  .submitButton {
    padding: 0.625rem 1.25rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .submitButton:hover {
    background-color: #1d4ed8;
  }
  
  @media (max-width: 640px) {
    .modal {
      max-width: 100%;
      margin: 1rem;
    }
  }