.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  border-top: 1px solid #e5e7eb;
  padding: 0.75rem;
  z-index: 40;
}

.footerContent {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.section {
  display: flex;
  align-items: center;
}

.supportText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.buttonIcon {
  transition: all 0.2s ease;
}

.donateButton,
.feedbackButton,
.coffeeButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.donateButton {
  border-color: #e5e7eb;
}

.coffeeButton {
  padding: 0;
}

.coffeeButton:hover {
  .supportText {
    color: #ef4444;
  }
}

.donateButton:hover {
  background: #fef2f2;
  border-color: #fecaca;
}

.feedbackButton:hover {
  background: #f3f4f6;
  border-color: #d1d5db;
}

.buttonText {
  display: inline;
}

/* Responsive styles */
@media (max-width: 640px) {
  .footerContent {
    padding: 0 0.5rem;
  }

  .supportText span {
    display: none;
  }

  .buttonText {
    display: none;
  }

  .donateButton,
  .feedbackButton {
    padding: 0.5rem;
  }

  .section {
    flex: 1;
    justify-content: center;
  }

  .rightSection {
    justify-content: flex-end;
  }
}

/* For extremely small screens */
@media (max-width: 360px) {
  .footerContent {
    gap: 0.5rem;
  }

  .donateButton,
  .feedbackButton {
    padding: 0.375rem;
  }
}