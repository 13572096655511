/* src/components/showcase/ShowcaseRating.module.css */
.container {
    margin: 1rem 0;
    position: relative;
}

.starsContainer {
    display: inline-flex;
    gap: 4px;
}

.star {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0;
    color: #d4d4d4;
    transition: color 0.2s, transform 0.1s;
}

.star:hover {
    transform: scale(1.1);
}

.star.filled {
    color: #ffd700;
}

/* Animation for newly rated stars */
.star.animate {
    animation: pulse 0.6s ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
        color: #ffcc00;
        text-shadow: 0 0 10px rgba(255, 215, 0, 0.7);
    }

    100% {
        transform: scale(1);
    }
}

.stats {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    font-size: 0.875rem;
}

.average {
    font-weight: 600;
    color: #333;
}

.total {
    color: #666;
}

.error {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: #fff2f2;
    color: #d32f2f;
    font-size: 0.875rem;
    animation: fadeIn 0.3s ease-in-out;
}

.success {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: #f0fff0;
    color: #2e7d32;
    font-size: 0.875rem;
    font-weight: 500;
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success::before {
    content: "✓";
    margin-right: 5px;
    font-weight: bold;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}